.default {
    background-color: #E4E5E6 !important;
}

.center-login {
    width: 468px;
    height: 400px;

    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;

    margin: auto;
}

.headText{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.logo{
    flex: auto !important;
}

.logo img {
    width: 150px !important;
}

@media screen and (max-width: 580px) {
    .logo img {
    //background-color: lightgreen;
    }
}

.modal-container{
    min-width: 430px !important;
}



@media screen and (max-width: 500px) {
    .modal-container{
        min-width: 330px !important;
    }
}

.input-error {
    color: red;
    font-size: 14px;
}
.card-n-m {
    border: 1px solid #ffffff !important;
}
.card {
    border: 1px solid #ffffff !important;
}

/*@media screen and (max-width: 768px) {*/
/*.card{*/
/*width: 350px;*/
/*}*/
/*}*/

@media screen and (max-width: 768px) {
    .card{
        width: 340px !important;
    }
}

@media screen and (max-width: 450px) {
    .card{
        width: 330px !important;
    }
}

@media screen and (max-width: 380px) {
    .card{
        width: 300px !important;
    }
}


.p-n-m {
    margin: 0px;
    text-align: right;
}

.p-n-m svg {
    cursor: pointer;
}

.card-body-np{
    /*padding: 0px !important;*/
}



@media screen and (max-width: 768px) {
    .card-n-m{
        width: 600px !important;
    }
}

@media screen and (max-width: 600px) {
    .card-n-m{
        width: 480px !important;
    }
}

@media screen and (max-width: 500px) {
    .card-n-m{
        width: 400px !important;
    }
}
@media screen and (max-width: 450px) {
    .card-n-m{
        width: 350px !important;
    }
}
@media screen and (max-width: 400px) {
    .card-n-m{
        width: 340px !important;
    }
}

/*@media screen and (max-width: 375px) {*/
/*.card-n-m{*/
/*width: 300px !important;*/
/*}*/
/*}*/