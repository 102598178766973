.main-menu {
    background-color: #2F353A;
    height: calc(100vh - 63px);
    overflow-y: auto;
    overflow-x: hidden;
}

@media only screen and (max-width: 500px) {
    .main-menu {
        min-height: calc(100vh);
    }
}

.main-menu svg {
    color: gray;
}
.main-menu a {
   color: #fff;
    font-size: 15px;
    overflow: initial;
}


 .main-menu ul div svg , .main-menu ul div span {
    color: #fff;
    font-size: 15px;
}

.main-menu a:hover {
    background-color: #20a8d8;
    color: #fff;
}

.main-menu a:hover svg{
    color: #fff;
}


.main-menu [class*='active']{
    background-color: #3a4248;
}

.main-menu [class*='active'] svg{
    color: #fff;
}

.main-menu a [class*='active']{

}

.active-menu{
    background-color: #3a4248;
}

.active-menu svg {
    color: #fff;
}