.table-wrapper {
    overflow-x: auto;
}

.table-wrapper table {
    width: 100%;
}

.table-wrapper table th, .table-wrapper table td {
    border: 1px solid #ddd;
    text-align: center;
    padding: 8px;
}

.table-wrapper table td {
    color: #626b6f;
}

.table-wrapper table tr {
    height: 50px;
}

.table-head {
    background-color: #8eb4cb;
}

.table-head th {
    color: #fff;
    font-weight: bold;
    height: 50px;
}

.clickable {
    color: #48a1d5;
    cursor: pointer;
}

.checkbox-wrapper {
    height: 28px;
    line-height: 28px;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-end;
}

.checkbox-wrapper input {
    height: 100%;
}

.checkbox-wrapper label {
    vertical-align: top;
    padding-left: 6px;
    flex: 1;
}

.icon-button {
    border: none;
    background-color: transparent;
    color: #48a1d5;
    margin: 1px;
}

.actions {
    width: 100px;
    text-align: left !important;
}

.select-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 8px;
}

.select-wrapper .select-field {
    width: 180px;
}

.select-wrapper label {
    line-height: 28px;
    font-weight: bold;
    margin-right: 8px;
}

.button {
    height: 28px;
    border: none;
    background: none;
    line-height: 28px;
    align-self: flex-end;
    color: #48a1d5;
}

.button-container {
    display: flex;
    align-items: flex-end;
    align-items: center;
    justify-content: flex-end;
    margin-top: 16px;
}
.error {
    font-size: 18px;
    font-weight: bold;
    color: red;
}

.form-container {
    display: flex;
}

.form-left {
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-left-label {
    width: 180px;
    margin-bottom: 0;
    font-weight: bold;
}

.form-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.form-right button {
    margin-left: 16px;
}

.flex-button {
    margin-right: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.db-container {
    display: flex;
    flex-direction: column;
}

.db-container label {
    margin-bottom: 4px;
}

.db-container select {
    width: 100%;
    max-width: 200px;
    margin-bottom: 16px;
}

.db-container input {
    width: 100%;
    max-width: 200px;
    margin-bottom: 16px;
}

i.arrow {
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 5px;
}

i.arrow.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

i.arrow.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

i.arrow.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

i.arrow.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}