.social-provider-card, .dashboard-card {
  margin: 0.5em;
  display: inline-block;
  vertical-align: top;
}

.social-provider-card {
  width: 250px;
  height: 300px;
}

.dashboard-card {
  padding: 5px;
  width: 400px;
  height: 300px;
}

.social-provider-card:hover, .dashboard-card:hover {
  box-shadow: 0px 1px 3px 0px black, 0px 1px 1px 0px black, 0px 2px 1px -1px black;
}

.social-provider-card a {
  text-decoration: none;
  color: black;
  text-align: center;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.social-provider-card a img {
  max-width: 200px;
  height: 100px;
}

.social-provider-card a:visited, .dashboard-card a:visited {
  color: black;
}

.social-provider-card a .info {
  padding: 5px;
  color: gray;
}

.submenu-align-right {
  justify-content: flex-end !important;
}

.dashboard-card a {
  text-decoration: none;
  color: black
}

.dashboard-card .content {
  min-height: 80%;
}